import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import Container from "../commons/Container"

const Wrapper = styled.footer`
  background-color: ${({ theme }) => theme.colors.fg};
  color: ${({ theme }) => theme.colors.bg};
  margin: 0;
  padding: 30px 0;

  h3 {
    font-size: 0.75rem;
    font-weight: 400;
    text-align: center;
  }
`

const LogosWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: space-between;
  list-style: none;
  margin: 0 auto;
  max-width: 400px;
  padding: 0;

  li {
    flex: 0 0 150px;

    & > div {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`

const Footer = () => (
  <Wrapper>
    <Container>
      <h3>apoio</h3>
      <LogosWrapper>
        <li>
          <StaticImage
            alt="Inesc"
            layout="fullWidth"
            src="../../assets/apoio/apoio-inesc.png"
          />
        </li>
        <li>
          <StaticImage
            alt="Plataforma pela Reforma do Sistema Político"
            layout="fullWidth"
            src="../../assets/apoio/apoio-plataforma.png"
          />
        </li>
      </LogosWrapper>
    </Container>
  </Wrapper>
)

export default Footer
