const getGroupLabel = id => {
  switch (id) {
    case "favor":
      return "a favor"
      break

    case "contra":
      return "contra"
      break

    case "muro":
      return "no muro"
      break

    default:
      return null
      break
  }
}

export default getGroupLabel
