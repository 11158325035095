import React from "react"
import propTypes from "prop-types"
import styled from "styled-components"

const Wrapper = styled.p`
  font-family: "Sedgwick Ave", sans-serif;
  font-size: 2rem;
  text-align: right;

  @media (min-width: 650px) {
    font-size: 3rem;
  }
`

const Hashtag = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

Hashtag.propTypes = {
  children: propTypes.string.isRequired,
}

export default Hashtag
