import React from "react"
import styled from "styled-components"

import getColor from "../../utils/getColor"
import getGroupLabel from "../../utils/getGroupLabel"

const Wrapper = styled.div`
  margin: 0 auto 90px;
  padding-top: 30px !important;
  position: relative;
  padding: 0;

  @media (min-width: 650px) {
    margin-bottom: 150px;
  }
`

const Meter = styled.div`
  align-items: stretch;
  height: calc(100vw * 0.08);
  max-height: 53px;
  display: flex;

  &:after {
    background: repeating-linear-gradient(
      to right,
      white 0%,
      white 33.33%,
      transparent 33.33%,
      transparent 100%
    );
    background-position: left top;
    background-repeat: repeat-x;
    background-size: 15px 1px;
    bottom: 0;
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 100vw;
    z-index: -1;
  }
`
const LabelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto 40px;
  max-width: 960px;
  padding: 0 10px;

  @media (min-width: 710px) {
    padding: 0;
  }
`

const Label = styled.p`
  --highlight: #fff;
  color: ${({ color }) => getColor(color)};
  display: block;
  font-size: 0.75rem;
  line-height: 1;

  &:nth-child(2) {
    text-align: center;
  }
  &:nth-child(3) {
    text-align: right;
  }

  strong {
    display: block;
    font-size: 2em;
  }

  @media (min-width: 650px) {
    font-size: 1.5rem;
  }
`

const Bar = styled.div`
  background-color: ${({ color }) => getColor(color)};
  display: inline-block;
  width: ${({ width }) => width * 100}%;
`

const Title = styled.h1`
  bottom: -3.5%;
  height: auto;
  left: 50%;
  margin: 0;
  position: absolute;
  resize: horizontal;
  transform: translate(-50%, 36%);
  width: 100%;

  @media (min-width: 650px) {
    bottom: -3%;
  }

  img {
    width: 100%;
  }
`

const MeterBar = ({ groupedData, total }) => {
  const BARS = ["favor", "muro", "contra"]
  const TOTAL_VOTES = total
  const NEEDED_VOTES = 342

  return (
    <Wrapper total={TOTAL_VOTES} needed={NEEDED_VOTES}>
      {/* Labels */}
      <LabelsContainer>
        {BARS.map(b => {
          return (
            groupedData[b] && (
              <Label color={b} key={b}>
                <strong>{groupedData[b]}</strong>
                {getGroupLabel(b)}
              </Label>
            )
          )
        })}
      </LabelsContainer>
      {/* Bars */}
      <Meter>
        {BARS.map(b => {
          return (
            groupedData[b] && (
              <Bar width={groupedData[b] / TOTAL_VOTES} color={b} key={b} />
            )
          )
        })}
      </Meter>
      <Title>
        <img src="/img/logo.svg" alt="Impixômetro - para além do placar" />
      </Title>
    </Wrapper>
  )
}

export default MeterBar
