import styled from "styled-components"

const Container = styled.div`
  margin: 0 auto;
  max-width: 710px;
  padding: 0 30px;

  @media (min-width: 710px) {
    max-width: 650px;
    padding: 0;
  }
`

export default Container
