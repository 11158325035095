import React from "react"
import styled from "styled-components"

const ColorSection = styled.section`
  h2,
  strong {
    color: var(--highlight);
  }

  h2:not(:first-child) {
    margin-top: -1em;
    position: relative;
  }
`

export default ColorSection
