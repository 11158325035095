import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

const SocialWrapperImpixometro = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px auto;
  max-width: 500px;

  a {
    border: 2px solid ${({ theme }) => theme.colors.bg};
    flex: 0 0 50px;
    outline-color: ${({ theme }) => theme.colors.bg};

    svg {
      max-width: 100%;
    }
  }
`

const HighlightBox = styled.div`
  background: ${({ theme }) => theme.colors.favor};
  color: ${({ theme }) => theme.colors.bg};
  left: -30px;
  padding: 15px 30px;
  position: relative;
  width: calc(100% + 60px);

  @media (min-width: 650px) {
    p {
      font-size: 1rem;
      text-align: center;
    }
  }
`

const Wrapper = styled.section`
  border: 2px solid ${({ theme }) => theme.colors.favor};
  padding: 30px;

  --highlight: ${({ theme }) => theme.colors.favor};

  & > *:first-child {
    margin-top: 0;
  }
  & > *:last-child {
    margin-bottom: 0;
  }

  h2,
  h3 {
    color: var(--highlight);
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5em;
  }

  ul {
    list-style: none;
    padding: 0 0 0 1.5em;
    margin-bottom: 2em;

    li {
      margin-bottom: 1em;
      position: relative;

      &:before {
        background: var(--highlight);
        content: "";
        height: 0.7em;
        left: -1.4em;
        position: absolute;
        top: 0.4em;
        width: 0.7em;
      }
    }
  }
`
const ComingSection = ({ children }) => <Wrapper>{children}</Wrapper>

ComingSection.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
}

export default ComingSection
export { HighlightBox, SocialWrapperImpixometro }
