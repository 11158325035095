import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled, { keyframes } from "styled-components"

import Container from "../commons/Container"
import MeterBar from "./MeterBar"

import getColor from "../../utils/getColor"

const flickering = keyframes`
  from {
    opacity: 0;
  }
  	5.999%, 7%, 7.999%, 19.999%, 22%, 62.999%, 64%, 64.999%, 70%, 95.555%, 97% {
		opacity: .99;
	}
	6%, 6.999%, 20%, 8%, 21.999%, 63%, 63.999%, 65%, 69.999%, 96%, 96.999% {
		opacity: 0.4;
	}
  to {
    opacity: 1;
  }
`

const show = keyframes`
  0% {
    transform: translate(-20%, 30%);
    opacity: 0;
  }
  10% {
    transform: none;
    opacity: 1;
  }
  95% {
    transform: none;
    opacity: 1;
  }
  100% {
    transform: none;
    opacity: 0;
  }
`

const Wrapper = styled.header`
  --highlight: transparent;

  margin: 0 auto 100px;
  padding-top: 1px;
  pointer-events: none;
  position: relative;

  &:before {
    background: url("../../img/19J-Fora-Bolsonaro_MST-DF_Foto-Scarlett-Rocha.jpg");
    background-position: center;
    background-size: cover;
    content: "";
    height: 110%;
    left: 0;
    filter: grayscale(1);
    opacity: 0.2;
    position: absolute;
    top: -10%;
    width: 100%;
    z-index: -1;
  }

  &:after {
    background: linear-gradient(
      to top,
      ${({ theme }) => theme.colors.bg},
      transparent
    );
    bottom: 0;
    content: "";
    height: 150px;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  ${Container} {
    max-width: 650px;
    padding: 0;
    position: relative;

    &:before {
      background: repeating-linear-gradient(
        to bottom,
        white 0%,
        white 33.33%,
        transparent 33.33%,
        transparent 100%
      );
      background-position: left top;
      background-repeat: repeat-y;
      background-size: 1px 15px;
      content: "";
      height: 100%;
      left: ${({ needed, total }) => (needed / total) * 100}%;
      position: absolute;
      width: 1px;
      z-index: 9;

      @media (min-width: 650px) {
        height: 90%;
      }
    }

    .needed {
      --highlight: #fff;
      bottom: 0;
      font-size: 0.875rem;
      left: ${({ needed, total }) => (needed / total) * 100}%;
      line-height: 1.2;
      padding-top: 5px;
      pointer-events: all;
      position: absolute;
      transform: translate(-50%, 100%);
      width: 14em;

      strong {
        display: block;
      }

      @media (min-width: 650px) {
        font-size: 1.3rem;
        margin: 0;
        max-width: 10em;
        padding: 0 0 30px 20px;
        transform: translate(0, 0);
      }
    }
  }
`

const ImageContainer = styled.div`
  left: -20%;
  position: relative;
  max-width: 650px;
  width: 130%;

  @media (min-width: 650px) {
    width: 90%;
  }

  .head {
    @media (prefers-reduced-motion: no-preference) {
      animation: ${flickering} 5s linear forwards infinite 3s;
    }
  }

  .xo {
    position: absolute;
    top: -5%;
    left: 20%;
    mix-blend-mode: hard-light;
    width: 70%;
    z-index: 9;

    @media (min-width: 650px) {
      left: 30%;
      top: -10%;
      width: 80%;
    }

    @media (prefers-reduced-motion: no-preference) {
      animation: ${show} 10s cubic-bezier(0.16, 1, 0.3, 1) forwards infinite 3s;
      opacity: 0;
    }
  }
`

const Hashtag = styled.span`
  --highlight: #fff;

  bottom: 30px;
  display: block;
  font-family: "Sedgwick Ave";
  font-size: 1.75rem;
  left: ${({ needed, total }) => (needed / total) * 100}%;
  line-height: 1.1;
  padding-right: 20px;
  pointer-events: all;
  position: absolute;
  text-align: right;
  transform: translateX(-100%);
  width: 4.2em;

  em {
    display: block;
    font-size: 2.85em;
    font-style: normal;
    line-height: 0.8;
  }

  @media (min-width: 650px) {
    font-size: 2.5rem;
  }
`

const PhotoCredit = styled.p`
  --highlight: #fff;
  bottom: 10px;
  left: calc(15% + 10px);
  font-size: 0.5rem;
  pointer-events: all;
  position: absolute;
  transform: rotate(-90deg);
  transform-origin: left top;

  @media (min-width: 650px) {
  }
`

const Header = ({ groupedData, total }) => {
  const TOTAL_VOTES = total
  const NEEDED_VOTES = 342

  return (
    <Wrapper total={TOTAL_VOTES} needed={NEEDED_VOTES}>
      <Container>
        <MeterBar groupedData={groupedData} total={total} />

        <ImageContainer>
          <StaticImage
            alt=""
            className="head"
            src="../../assets/header.png"
            layout="fullWidth"
            loading="eager"
            placeholder="blurred"
          />
          <StaticImage
            alt=""
            className="xo"
            loading="eager"
            src="../../assets/xo.png"
            width={500}
            placeholder="none"
          />
          <PhotoCredit>
            Fotos: Alan Santos e Scarlett Rocha/Fotos Públicas
          </PhotoCredit>
        </ImageContainer>

        <Hashtag total={TOTAL_VOTES} needed={NEEDED_VOTES}>
          <em>#</em>Bora Derrubá Esse Omi
        </Hashtag>
        <p className="needed" style={{ "--highlight": getColor("favor") }}>
          <strong>{`${NEEDED_VOTES} votos necessários`}</strong>{" "}
          {`na Câmara dos Deputados para abertura do processo`}
        </p>
      </Container>
    </Wrapper>
  )
}

export default Header
