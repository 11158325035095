import * as React from "react"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import Button from "../components/commons/Button"
import {
  CopyBox,
  PressureContainer,
  PressureSection,
  SocialBox,
} from "../components/Home/PressureSection"
import ComingSection, {
  HighlightBox,
  SocialWrapperImpixometro,
} from "../components/Home/ComingSection"
import Container from "../components/commons/Container"
import ColorSection from "../components/commons/ColorSection"
import Hashtag from "../components/commons/Hashtag"
import Header from "../components/Header"
import Layout from "../components/Layout"
import Seo from "../components/seo"

import getColor from "../utils/getColor"
import impixometroConfig from "../../impixometro.config.json"

import DesdobraLogo from "../assets/desdobra.svg"
import EmailLogo from "../assets/email.svg"
import FacebookLogo from "../assets/facebook.svg"
import GmailLogo from "../assets/gmail.svg"
import InstagramLogo from "../assets/instagram.svg"
import TelegramLogo from "../assets/telegram.svg"
import TelefoneLogo from "../assets/telefone.svg"
import TwitterLogo from "../assets/twitter.svg"
import Footer from "../components/Home/Footer"
import Tippy from "@tippyjs/react"

const ImageBanner = styled.div`
  display: grid;
  left: 50%;
  min-height: 250px;
  position: relative;
  transform: translateX(-50%);
  width: 100vw;

  &:after {
    background: linear-gradient(
      to top,
      ${({ theme }) => theme.colors.bg},
      transparent
    );
    bottom: 0;
    content: "";
    height: 20%;
    width: 100%;
    position: absolute;
  }
`

const ImageBannerLira = styled.div`
  display: grid;
  left: -30px;
  position: relative;
  width: calc(100% + 60px);

  & > div {
    max-height: 300px;
  }

  @media (min-width: 650px) {
    flex: 0 0 35%;
    left: auto;
    & > div {
      max-height: none;
    }
  }
`

const MethodTitle = styled.h3`
  color: var(--highlight);
  font-size: 1.5625rem;
  font-weight: 700;
  margin-bottom: 0.5em;
  position: relative;

  span {
    background: ${({ theme }) => theme.colors.bg};
    padding: 0.25em;
  }

  &::before {
    background-color: var(--highlight);
    bottom: 0.3em;
    content: "";
    height: 1px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 100vw;
    z-index: -1;
  }

  @media (min-width: 650px) {
    left: -0.25em;

    span {
      font-size: 2rem !important;
    }
  }
`

const PhotoCredit = styled.p`
  top: 10px;
  font-size: 0.6rem !important;
  margin: 0;
  opacity: 0.8;
  position: absolute;
  left: 5px;
  transform: rotate(-90deg) translate(-100%, 0);
  transform-origin: left top;
  z-index: 9;

  @media (min-width: 650px) {
    bottom: 0;
    left: auto;
    right: 15px;
    top: auto;
    transform: none;
  }
`

const Wrapper = () => {
  const DATA = {
    contra: 154,
    favor: 117,
    muro: 241,
  }
  const TOTAL = Object.values(DATA).reduce((prev, total) => prev + total)
  const [isMobile, setIsMobile] = useState(true)

  useEffect(() => {
    const updateSize = () => {
      setIsMobile(window.innerWidth < 768)
    }
    window.addEventListener("resize", updateSize)
    updateSize()

    return () => window.removeEventListener("resize", updateSize)
  }, [])

  return (
    <Layout>
      <Seo lang="pt-br" />
      <Header groupedData={DATA} total={TOTAL} />

      <Container>
        {/* POR QUE */}
        <ColorSection style={{ "--highlight": getColor("favor") }}>
          <h2 color="favor">
            impeachment
            <br />
            por quê?
          </h2>
          <p>
            Depois de quase três anos de Bolsonaro no cargo, o Brasil passa por
            seu <strong>pior momento desde a redemocratização</strong>. Há quase
            dois anos convivemos com crises social, institucional, política,
            econômica, sanitária, climática e energética, em um cenário pior que
            qualquer uma das previsões sobre seu governo feitas em 2018. São
            dezenas - senão centenas - de crimes de responsabilidade descritos
            nos <strong>137 pedidos de impeachment</strong> protocolados na
            Câmara dos Deputados. Isso torna Arthur Lira corresponsável por essa
            tragédia nacional.
          </p>

          <p style={{ display: "flex", margin: "30px 0" }}>
            <Button color="muro" href="#aperte-o-lira">
              <span style={{ "--highlight": "#fff", padding: "0.5em" }}>
                aperte o Lira!
              </span>
            </Button>
          </p>

          <Hashtag>#XôBolsonaro</Hashtag>
        </ColorSection>

        {/* PRA QUEM */}
        <ColorSection style={{ "--highlight": getColor("favor") }}>
          <ImageBanner>
            <StaticImage
              style={{
                gridArea: "1/1",
              }}
              layout="fullWidth"
              aspectRatio={3 / 1}
              alt=""
              src="../assets/ForaBolsonaro.png"
              formats={["auto", "webp", "avif"]}
            />
            <PhotoCredit>Fotos: Ricardo Stuckert /Fotos Públicas</PhotoCredit>
          </ImageBanner>

          <h2>pra quem?</h2>
          <p>
            O governo Bolsonaro trabalhou ativamente para espalhar o coronavírus
            pelo Brasil. Por conta de sua gestão da pandemia, ao menos{" "}
            <strong>600 mil brasileiros morreram</strong>. Pelo menos 400 mil
            dessas mortes aconteceram depois do lançamento de vacinas. Cada dia
            que Jair Bolsonaro passa na presidência representa o empobrecimento
            e a morte de mais brasileiros.
          </p>
          <p>
            Nada que não seja o impedimento e o julgamento de Bolsonaro e seus
            cúmplices serve ao Brasil nesse momento. É urgente tirar Bolsonaro
            para garantir a retomada da cidadania para brasileiros e brasileiras
            que hoje se encontram sem emprego, sem vacina e sem dignidade.
          </p>
          <Hashtag>#XôGenocida</Hashtag>
        </ColorSection>

        {/* PRA QUÊ */}
        <ColorSection style={{ "--highlight": getColor("favor") }}>
          <ImageBanner>
            <StaticImage
              style={{
                gridArea: "1/1",
              }}
              layout="fullWidth"
              aspectRatio={3 / 1}
              alt=""
              src="../assets/BolsonarismoMata.png"
              formats={["auto", "webp", "avif"]}
              placeholder="blurred"
            />
            <PhotoCredit>Foto: Allan White/FotosPublicas</PhotoCredit>
          </ImageBanner>
          <h2>pra quê?</h2>
          <p>
            O Brasil precisa ser vacinado contra Bolsonaro. Ele e seus cúmplices
            devem passar pelo processo de impedimento e{" "}
            <strong>perder seus direitos políticos</strong>, para que jamais
            voltem a ameaçar o país
          </p>
          <Hashtag>#XôDisgraça</Hashtag>
        </ColorSection>

        {/* O CÚMPLICE */}
        <ColorSection style={{ "--highlight": getColor("muro") }}>
          <ImageBanner>
            <StaticImage
              style={{
                gridArea: "1/1",
              }}
              layout="fullWidth"
              aspectRatio={3 / 1}
              alt=""
              transformOptions={{ cropFocus: "right" }}
              src="../assets/Cumplice.png"
              formats={["auto", "webp", "avif"]}
              placeholder="blurred"
            />
            <PhotoCredit>Foto: Isac Nóbrega/FotosPublicas</PhotoCredit>
          </ImageBanner>
          <h2>o cúmplice</h2>
          <p>
            Se formos colocar a culpa do caos em que o Brasil se encontra em um
            homem, Jair Bolsonaro ganha de lavada. Mas, se abrirmos para
            escolher um segundo,{" "}
            <strong>o posto é do presidente da Câmara dos Deputados</strong>,
            Arthur Lira (PP-AL). É dele a chave da gaveta que no momento está
            abarrotada com 137 pedidos de impeachment e contando.
          </p>
        </ColorSection>

        {/* APERTA QUE VAI */}
        <ColorSection style={{ "--highlight": getColor("muro") }}>
          <h2>aperta que vai!</h2>
          <p>
            Lira, assim como seu antecessor Rodrigo Maia (DEM-RJ), se escuda no
            que entende ser uma prerrogativa de seu cargo. Mas ele está errado:
            a legislação determina que a <strong>análise dos pedidos</strong> de
            impeachment <strong>é um DEVER</strong> do presidente da Câmara, não
            escolha. Então, é pressão nele!
          </p>
          <Hashtag>#LiberaLira</Hashtag>
        </ColorSection>

        {/* PRESSÃO NO LIRA */}
        <PressureContainer style={{ "--highlight": getColor("favor") }}>
          <ImageBannerLira>
            <StaticImage
              style={{
                gridArea: "1/1",
              }}
              layout="fullWidth"
              aspectRatio={4 / 5}
              alt=""
              transformOptions={{ cropFocus: "center" }}
              src="../assets/lira.png"
              formats={["auto", "webp", "avif"]}
              placeholder="blurred"
            />
            <PhotoCredit>Foto: Isac Nóbrega/FotosPublicas</PhotoCredit>
          </ImageBannerLira>
          <PressureSection name="aperte-o-lira" id="aperte-o-lira">
            <h2>ARTHUR CÉSAR PEREIRA DE LIRA</h2>
            <p>
              dep.arthurlira@camara.leg.br
              <br />
              (61) 3215-5942
            </p>
            <p>
              <strong>Gabinete 942</strong> – Anexo IV Câmara dos Deputados
            </p>

            <CopyBox style={{ "--highlight": "#fff" }}>
              Arthur Lira, é seu DEVER como presidente da Câmara dar andamento
              aos pedidos de impeachment contra Bolsonaro. Não entre para a
              história como cúmplice do pior presidente que o Brasil já elegeu.
              #LiberaLira #BoraDerrubáEsseOmi
            </CopyBox>

            <p style={{ marginBottom: 0 }}>
              Use a mensagem acima para <strong>pressionar o deputado</strong>{" "}
              em seus canais de comunicação:
            </p>
            <SocialBox>
              <Tippy content="Facebook">
                <Button
                  color="favor"
                  href="https://www.facebook.com/arthurliradeputadofederal/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FacebookLogo />
                </Button>
              </Tippy>
              <Tippy content="Twitter">
                <Button
                  color="favor"
                  href={encodeURI(
                    `https://twitter.com/intent/tweet?text=${impixometroConfig.share.social.twitter.message}&url=${impixometroConfig.share.social.twitter.url}&hashtags=${impixometroConfig.share.social.twitter.hashtags}&via=${impixometroConfig.share.social.twitter.via}`
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterLogo />
                </Button>
              </Tippy>
              <Tippy content="Instagram">
                <Button
                  color="favor"
                  href="https://www.instagram.com/oficialarthurlira/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramLogo />
                </Button>
              </Tippy>
              <Tippy content="E-mail">
                <Button
                  color="favor"
                  href={encodeURI(
                    `mailto:${impixometroConfig.share.email.to}?&subject=${impixometroConfig.share.email.subject}&body=${impixometroConfig.share.email.body}`
                  )}
                  target="_blank"
                >
                  <EmailLogo />
                </Button>
              </Tippy>

              {/* Gmail for desktop */}
              {!isMobile && (
                <Tippy content="Gmail">
                  <Button
                    color="favor"
                    href={encodeURI(
                      `https://mail.google.com/mail/?view=cm&fs=1&to=${impixometroConfig.share.email.to}&su=${impixometroConfig.share.email.subject}&body=${impixometroConfig.share.email.body}`
                    )}
                    target="_blank"
                  >
                    <GmailLogo />
                  </Button>
                </Tippy>
              )}

              {/* Telephone for mobile */}
              {isMobile && (
                <Tippy content="Telefone">
                  <Button
                    color="favor"
                    href="tel:+556132155942"
                    target="_blank"
                  >
                    <TelefoneLogo />
                  </Button>
                </Tippy>
              )}
            </SocialBox>
          </PressureSection>
        </PressureContainer>

        {/* VEM AI */}
        <ComingSection>
          <h2>vem mais por aí!</h2>
          <p>
            Essa não é a forma final do impiXÔmetro. O projeto prevê novas
            funcionalidades para facilitar a pressão sobre deputados e deputadas
            que insistem em não se posicionar contra esse governo de morte.
          </p>
          {/* <h3>versão completa:</h3> */}
          <ul>
            <li>
              lista com o posicionamento de todos os deputados e deputadas
            </li>
            <li>ferramenta para envio de mensagens de pressão</li>
            <li>
              filtro para busca por posicionamento, partido, estado e município
            </li>
          </ul>
          <p>
            <strong style={{ color: "var(--highlight)" }}>
              Em breve, você vai poder focar no/a parlamentar da sua cidade.
            </strong>
          </p>
          <HighlightBox>
            <p style={{ "--highlight": "#fff" }}>
              <strong>Siga</strong> a gente <strong>nas redes</strong> para
              ficar por dentro das atualizações:
            </p>
          </HighlightBox>
          <SocialWrapperImpixometro>
            <Tippy content="Facebook">
              <Button
                color="favor"
                href="https://www.facebook.com/impixometro/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookLogo />
              </Button>
            </Tippy>
            <Tippy content="Twitter">
              <Button
                color="favor"
                href="https://www.twitter.com/impixometro"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterLogo />
              </Button>
            </Tippy>
            <Tippy content="Instagram">
              <Button
                color="favor"
                href="https://www.instagram.com/impixometro/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramLogo />
              </Button>
            </Tippy>
            <Tippy content="Telegram">
              <Button
                color="favor"
                href="https://t.me/impixometro/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TelegramLogo />
              </Button>
            </Tippy>
          </SocialWrapperImpixometro>
          <p>
            Você também pode acessar nossos materiais de divulgação para ajudar
            na campanha, nas redes e nas ruas
          </p>
          <p style={{ display: "flex", margin: "30px 0" }}>
            <Button
              color="favor"
              target="_blank"
              href="https://mega.nz/folder/MokyTD5b#tI5jyjy35CxPxXL6q4Xo6w"
              rel="noopener noreferrer"
            >
              <span style={{ "--highlight": "#fff", padding: "0.5em 0" }}>
                baixe o kit de divulgação
              </span>
            </Button>
          </p>
          <Hashtag>#PressãoNeles</Hashtag>
        </ComingSection>

        {/* METODOLOGIA */}
        <ColorSection style={{ "--highlight": "#fff" }}>
          <h2>metodologia</h2>
          <p>
            A posição de cada parlamentar foi apurada jornalisticamente, por
            diversos meios: declarações públicas, participação em documentos
            (como assinatura de pedidos de impeachment), postagens nas redes
            sociais e contato direto com os gabinetes por e-mail e/ou telefone.
            Evidências foram armazenadas como forma de garantir a idoneidade do
            processo.
          </p>
          <p>
            A despeito disso, e pela própria natureza do processo político de
            impeachment, estamos abertos para correções e mudança de posição a
            qualquer momento. Para isso, basta que o/a parlamentar, ou sua
            equipe, envie uma posição oficial pelo e-mail:
          </p>
          <p>
            <a
              style={{ color: getColor("favor") }}
              href="mailTo:impixometro@riseup.net"
            >
              impixometro@riseup.net
            </a>
          </p>

          <p>
            Utilizamos como referência para essa apuração o chamado Super Pedido
            de Impeachment. Apresentado por um arco amplo de partidos e
            parlamentares, o pedido buscou consolidar os diversos crimes de
            responsabilidade cometidos por Bolsonaro em um documento único.
            Dessa forma, trata-se da ação mais abrangente pelo impedimento do
            presidente tanto do ponto de vista jurídico quanto da
            representatividade política.
          </p>
          <p>
            A partir da apuração, os parlamentares foram enquadrados em 3
            categorias com base nos seguintes critérios:
          </p>

          <MethodTitle style={{ "--highlight": getColor("favor") }}>
            <span>a favor</span>
          </MethodTitle>
          <p>
            Foram classificados nesta categoria parlamentares que se
            posicionaram claramente a favor da aprovação do impeachment do
            presidente Jair Bolsonaro em algum momento nos últimos dois meses.
          </p>

          <MethodTitle style={{ "--highlight": getColor("muro") }}>
            <span> no muro</span>
          </MethodTitle>
          <p>
            Foram classificados nesta categoria parlamentares (1) sobre os quais
            não encontramos posicionamentos claros contra ou a favor do
            impeachment do presidente Jair Bolsonaro; (2) que não responderam
            nossos contatos por e-mail e/ou telefone para seus gabinetes; ou (3)
            que responderam aos contatos e declararam não ter ainda uma posição
            definida a respeito do tema.
          </p>

          <MethodTitle style={{ "--highlight": getColor("contra") }}>
            <span>contra</span>
          </MethodTitle>
          <p>
            Foram classificados nesta categoria parlamentares que, em algum
            momento nos últimos dois meses: (1) se posicionaram claramente
            contra a aprovação do impeachment do presidente Jair Bolsonaro; ou
            (2) demonstraram em suas redes sociais e outras declarações públicas
            apoio explícito ao presidente.
          </p>
        </ColorSection>

        {/* DESDOBRA */}
        <ColorSection style={{ "--highlight": "#fff" }}>
          <h2>quem somos</h2>
          <p>
            O <strong>impiXÔmetro</strong> é uma ferramenta de pressão sobre
            os/as parlamentares para que apoiem o impeachment de Jair Bolsonaro,
            idealizada pelo Desdobra Coletivo.
          </p>
          <p>
            Fundado em 2019, o <strong>Desdobra Coletivo</strong> tem como
            objetivo unir política, comunicação, cultura e tecnologia para
            discutir temas caros à sociedade, sempre buscando dar voz a
            movimentos sociais e grupos minorizados, além de promover a
            democracia e o combate à desigualdade.
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "60px",
            }}
          >
            <span className="sr-only">Realização: Desdobra Coletivo</span>
            <DesdobraLogo />
          </div>
        </ColorSection>
      </Container>

      {/* FOOTER */}
      <Footer />
    </Layout>
  )
}

export default Wrapper
