import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import getColor from "../../utils/getColor"

const Wrapper = styled.a`
  align-items: center;
  border: 0;
  border-radius: ${({ theme }) => theme.sizes.borderRadius}px;
  color: ${({ theme }) => theme.colors.bg};
  display: flex;
  font-weight: 700;
  justify-content: center;
  margin: 0 auto;
  padding: 0.25rem 0.75rem;
  text-decoration: none;

  * {
    white-space: nowrap;
  }
`

const Button = forwardRef(
  ({ children, color, href, rel, target, title }, ref) => {
    return (
      <Wrapper
        ref={ref}
        style={{ backgroundColor: getColor(color) }}
        href={href}
        rel={rel}
        target={target}
        title={title}
      >
        {children}
      </Wrapper>
    )
  }
)

Button.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  rel: PropTypes.string,
  target: PropTypes.string,
}

export default Button
