// es-lint-ignore-no-unreachable
import theme from "../styles/theme.json"

const getColor = strColor => {
  switch (strColor.toLowerCase()) {
    case "favor":
      return theme.colors.favor
      break

    case "contra":
      return theme.colors.against
      break

    case "against":
      return theme.colors.against
      break

    case "muro":
      return theme.colors.fence
      break

    case "fence":
      return theme.colors.fence
      break

    default:
      return theme.colors.against
  }
}

export default getColor
