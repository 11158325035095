import PropTypes from "prop-types"
import React, { useState } from "react"
import styled from "styled-components"
import Tippy from "@tippyjs/react"
import "tippy.js/dist/tippy.css"

import CopySVG from "../../assets/copy.svg"

// *****
// Pressure Container
// *****
const PressureContainer = styled.div`
  margin: 60px 0;
  max-width: 650px;

  @media (min-width: 650px) {
    display: flex;

    h2 {
      font-size: 1.5rem;
    }
    p {
      font-size: 1rem;
      line-height: 1.2;
    }
  }
`

// *****
// Pressure Section
// *****
const WrapperPression = styled.section`
  background: ${({ theme }) => theme.colors.fence};
  color: ${({ theme }) => theme.colors.bg};
  left: -30px;
  padding: 30px 30px 90px;
  position: relative;
  width: calc(100% + 60px);

  @media (min-width: 650px) {
    left: auto;
  }
`

const InnerSection = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.bg};
  padding: 30px;

  h2 {
    font-size: 1.875rem;
  }

  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }
`

const PressureSection = ({ children, name, id }) => (
  <WrapperPression name={name} id={id}>
    <InnerSection>{children}</InnerSection>
  </WrapperPression>
)

PressureSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

// *****
// Inner box text copy
// *****

const WrapperCopyBox = styled.div`
  background: ${({ theme }) => theme.colors.favor};
  border: 2px solid ${({ theme }) => theme.colors.bg};
  font-style: italic;
  left: -45px;
  padding: 20px;
  position: relative;
  width: calc(100% + 90px);
`

const CopyButton = styled.button`
  align-items: center;
  background: transparent;
  bottom: 0;
  border: none;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  outline-color: ${({ theme }) => theme.colors.bg};
  position: absolute;
  right: 0;
  width: 40px;
`

const CopyBox = ({ children, style }) => {
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false)
  let timeoutFeedback

  const clickToCopy = () => {
    navigator.clipboard.writeText(children)
    setIsFeedbackVisible(true)
    clearTimeout(timeoutFeedback)
    timeoutFeedback = setTimeout(() => setIsFeedbackVisible(false), 1000)
  }

  return (
    <WrapperCopyBox style={style}>
      <Tippy content="texto copiado!" visible={isFeedbackVisible}>
        <p>{children}</p>
      </Tippy>
      {typeof navigator !== "undefined" && navigator?.clipboard && (
        <Tippy content="copiar texto">
          <CopyButton onClick={clickToCopy}>
            <CopySVG />
          </CopyButton>
        </Tippy>
      )}
    </WrapperCopyBox>
  )
}

CopyBox.propTypes = {
  children: PropTypes.string.isRequired,
}

// *****
// Inner box social icons
// *****

const SocialWrapper = styled.div`
  bottom: 20px;
  display: flex;
  justify-content: space-between;
  left: 50%;
  max-width: 500px;
  position: absolute;
  transform: translateX(-50%);
  width: calc(100% - 30px);

  a {
    border: 2px solid ${({ theme }) => theme.colors.bg};
    flex: 0 0 50px;
    outline-color: ${({ theme }) => theme.colors.bg};

    svg {
      max-width: 100%;
    }
  }
`

const SocialBox = ({ children }) => <SocialWrapper>{children}</SocialWrapper>

export { CopyBox, PressureContainer, PressureSection, SocialBox }
